import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import useHoverActionCell from "../../../crossCutting/hooks/useHoverActionCell";
import { Pageable } from "../../../crossCutting/Pagination";
import { useTranslation } from "../../../i18n/i18n";
import { IfCanRegisterOthersInContext } from "../IfCanRegisterInContext";
import {
  LinkToMemberEdit,
  memberEditPagePath,
} from "../memberEditPage/routing";
import { LinkToRegistrationNew } from "../registrationEditPage/routing";

const useStyles = makeStyles((theme) => ({
  numberCell: {
    textAlign: "right",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tableContainer: {
    flexGrow: 1,
  },
  tablePagination: {
    flexShrink: 0,
  },
}));

const MemberTable: React.FunctionComponent<MemberTableProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { rowClass, HoverActionCell } = useHoverActionCell();
  const handleChangePage = (_: any, page: number) => {
    props.pageChanged(props.rowsPerPage, page);
  };
  const handleChangeRowsPerPage = (e: any) => {
    const parsed = parseInt(e.target.value);
    props.pageChanged(parsed, 0);
  };
  return (
    <div className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("lastName")}</TableCell>
              <TableCell>{t("firstName")}</TableCell>
              <TableCell className={classes.numberCell}>
                {t("number")}
              </TableCell>
              <TableCell>{t("postalCodeShort")}</TableCell>
              <TableCell>{t("city")}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.members.map((m) => (
              <TableRow
                className={rowClass}
                key={m.memberId}
                hover
                onClick={() =>
                  history.push(
                    memberEditPagePath(props.exhibitionId, m.memberId)
                  )
                }
              >
                <TableCell>{m.lastName}</TableCell>
                <TableCell>{m.firstName}</TableCell>
                <TableCell className={classes.numberCell}>
                  {m.memberNumber}
                </TableCell>
                <TableCell>{m.zipCode}</TableCell>
                <TableCell>{m.city}</TableCell>
                <HoverActionCell>
                  <LinkToMemberEdit
                    exhibitionId={props.exhibitionId}
                    memberId={m.memberId}
                    button
                    color="primary"
                    size="small"
                  >
                    {t("edit")}
                  </LinkToMemberEdit>
                </HoverActionCell>
                <HoverActionCell>
                  <IfCanRegisterOthersInContext>
                    <LinkToRegistrationNew
                      exhibitionId={props.exhibitionId}
                      memberId={m.memberId}
                      button
                      color="primary"
                      size="small"
                    >
                      {t("registerMember")}
                    </LinkToRegistrationNew>
                  </IfCanRegisterOthersInContext>
                </HoverActionCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.tablePagination}
        rowsPerPageOptions={[100, 1000]}
        component="div"
        count={props.totalCount}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        SelectProps={{
          inputProps: { "aria-label": "Zeilen pro Seite" },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default MemberTable;

interface MemberTableProps extends Pageable {
  members: Member[];
  exhibitionId: string;
}

export interface Member {
  memberId: string;
  memberNumber: number;
  firstName: string;
  lastName: string;
  zipCode: string | null;
  city: string | null;
}
