import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  FormSectionTitle,
  FormTitle,
} from "../../../crossCutting/FormSectionTitle";
import {
  combineStates,
  mapState,
  success,
} from "../../../crossCutting/hooks/usePromiseStates";
import useSaveShortcut from "../../../crossCutting/hooks/useSaveShortcut";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import BottomBarPage from "../../../crossCutting/pages/BottomBarPage";
import Radios from "../../../crossCutting/Radios";
import { parseQuery } from "../../../crossCutting/urls";
import { useFetch2, useSendJson } from "../../../fetching/fetchProvider";
import { Competition, Organization } from "../apiTypes";
import {
  useRefreshExhibition,
  useExhibitionCached,
} from "../ExhibitionContext";
import { Exhibition } from "../types";
import {
  allChampionLevels,
  ChampionLevelKey,
} from "./championLevel/championLevel";
import ChampionLevelItem from "./championLevel/ChampionLevelItem";
import { allMedals, Medal } from "./medal/medal";
import MedalItem from "./medal/MedalItem";
import { MedalDistribution, medalDistributions } from "./medalDistribution";
import OrganizationList from "../organization/OrganizationList";
import { allRankingLevels, RankingLevelKey } from "./rankingLevelStatic";
import { RankingMode, rankingModes } from "./rankingMode";
import { RatingAggregation, ratingAggregations } from "./ratingAggregation";
import { competitionEditPagePath } from "./routing";
import UnitTable from "./unit/UnitTable";
import { useTranslatorForUser } from "../../masterdata/translation";
import PointCalculationRadios from "../PointCalculationRadios";
import {
  PointCalculation,
  applicablePointCalculations,
} from "../pointCalcultation";

export type CompetitionEditExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;
export interface CompetitionEditPageProps {
  exhibition: CompetitionEditExhibition;
}

const useStyles = makeStyles((theme) => ({
  radioInline: {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}));
const CompetitionEditPage: React.FunctionComponent<CompetitionEditPageProps> = (
  props
) => {
  const location = useLocation();
  const animalStandardIdFromQuery = parseQuery(
    location.search
  ).animalStandardId;
  const { competitionId } = useParams<{ competitionId: string }>();
  const isNewCompetition = !competitionId;
  const exhibitionId = props.exhibition.exhibitionId;

  const [existingCompetition] = useFetch2<Competition>(
    isNewCompetition
      ? ""
      : `exhibitions/${exhibitionId}/competitions/${competitionId}`
  );
  const editedCompetition: any = isNewCompetition
    ? success({ animalStandardId: animalStandardIdFromQuery })
    : existingCompetition;

  const exhibition = useExhibitionCached();
  const [categories] = useFetch2<{
    categories: { animalStandardId: string }[];
  }>(`/exhibitions/${exhibitionId}/categories`);
  const animalStandard = mapState(editedCompetition, (c: any) =>
    exhibition.animalStandards.find(
      (s) => s.animalStandardId === c.animalStandardId
    )
  );
  const hasCategories = combineStates(categories, animalStandard, (c, s) =>
    c.categories.some((c) => c.animalStandardId === s?.animalStandardId)
  );
  const organizations = mapState(
    useFetch2<{ organizations: Organization[] }>(
      `exhibitions/${exhibitionId}/organizations`
    )[0],
    (d) => d.organizations
  );

  if (
    editedCompetition.isSuccess() &&
    animalStandard.isSuccess() &&
    organizations.isSuccess() &&
    hasCategories.isSuccess()
  ) {
    return (
      <ActualEditPage
        animalStandard={animalStandard.data!}
        competition={editedCompetition.data}
        allExhibitionOrganizations={organizations.data}
        hasCategories={hasCategories.data}
        exhibition={props.exhibition}
      />
    );
  } else {
    const combined = combineStates(
      organizations,
      combineStates(animalStandard, editedCompetition, () => null),
      () => null
    );
    return combined.isRunning() ? <LoadingIndicator /> : <>Error</>;
  }
};

// This is the "safe zone" where all the necessary data has successfully been loaded.
function ActualEditPage({
  animalStandard,
  competition,
  exhibition,
  allExhibitionOrganizations,
  hasCategories,
}: {
  animalStandard: AnimalStandard;
  competition: Competition;
  exhibition: CompetitionEditExhibition;
  allExhibitionOrganizations: Organization[];
  hasCategories: boolean;
}) {
  const classes = useStyles();
  const isNewCompetition = !competition.competitionId;

  const translator = useTranslatorForUser();
  const translatedUnits = animalStandard.units.map((u) => {
    return {
      ...u,
      ...(translator(u.captions) || { unitName: "n/a" }),
    };
  });

  const [units, setUnits] = React.useState(
    translatedUnits.map((u) => {
      const cu = competition.competitionUnits?.find(
        (cu) => cu.unitId === u.unitId
      );
      return {
        ...(cu || { isGenderMandatory: false, strikeResult: null }),
        unit: u,
        isSelected: !!cu,
      };
    })
  );
  const [championLevels, setChampionLevels] = React.useState(
    allChampionLevels
      .filter(
        (l) =>
          l.level === ChampionLevelKey.BreedChampion ||
          animalStandard.hasColorLevel
      )
      .map((l) => {
        const x =
          l.level === ChampionLevelKey.BreedChampion
            ? competition.champions?.breedChampion
            : competition.champions?.colorChampion;
        const xWithString = x
          ? { ...x, animalMin: x?.animalMin.toString() }
          : undefined;
        return { ...l, ...xWithString, isSelected: !!x };
      })
  );

  const history = useHistory();

  // Depends on standard
  const [pointCalculation, setPointCalculation] = React.useState(
    competition.pointCalculation ||
      applicablePointCalculations(animalStandard)[0].value
  );

  const [ratingAggregation, setRatingAggregation] = React.useState(
    competition.ratingAggregation || RatingAggregation.Average
  );

  const [rankingMode, setRankingMode] = React.useState(
    competition.rankingMode || RankingMode.OneTwoTwoThree
  );

  const applicableRankingLevelKeys = [
    RankingLevelKey.Standard,
    animalStandard?.hasBreedSizeLevel && RankingLevelKey.BreedSize,
    animalStandard?.hasColorLevel && RankingLevelKey.Color,
    animalStandard?.hasAgeClass && RankingLevelKey.AgeClass,
    RankingLevelKey.Breed,
    RankingLevelKey.Gender,
    hasCategories && RankingLevelKey.Category,
  ];
  const applicableRankingLevels = allRankingLevels.filter((i) =>
    applicableRankingLevelKeys.some((a) => a === i.value)
  );
  const [rankingLevel, setRankingLevel] = React.useState(
    competition.rankingLevel || RankingLevelKey.Standard
  );

  const [medalDistribution, setMedalDistribution] = React.useState(
    competition.medalDistribution || MedalDistribution.ByRank
  );

  const [medals, setMedals] = React.useState(
    allMedals.map((m) => {
      const x =
        m.medal === Medal.Gold
          ? competition.medals?.gold
          : m.medal === Medal.Silver
          ? competition.medals?.silver
          : competition.medals?.bronze;
      const withNumberLimit = { ...m, ...x, isSelected: !!x };
      return { ...withNumberLimit, limit: withNumberLimit.limit.toString() };
    })
  );

  const [hasSingleMedal, setSingleMedal] = React.useState(
    competition.hasSingleMedal
  );

  const [hasSwissChampion, setHasSwissChampion] = React.useState(
    competition.hasSwissChampion
  );

  const [organizations, setOrganizations] = React.useState(
    allExhibitionOrganizations.filter((o) =>
      competition.organizationIds?.some((id) => o.organizationId === id)
    )
  );
  const [competitionName, setCompetitionName] = React.useState(
    competition.competitionName
  );
  const handleCompetitionNameChange = (e: any) =>
    setCompetitionName(e.target.value);

  const championLevelsValid = !championLevels?.some(
    (c) => c.isSelected && (!c.championCode || isNaN(parseInt(c.animalMin)))
  );
  const medalsValid = !medals?.some(
    (m) => m.isSelected && (!m.medalCode || isNaN(parseInt(m.limit)))
  );
  const valid =
    animalStandard &&
    competitionName &&
    units?.some((u) => u.isSelected) &&
    championLevelsValid &&
    medalsValid;

  const { sendJson, loading: saving } = useSendJson({
    success: "Die Wertung wurde gespeichert",
    error: "Fehler beim Speichern der Wertung",
  });

  const refreshGlobalExhibition = useRefreshExhibition();

  useSaveShortcut(() => {
    if (!valid) return;
    if (isNewCompetition) handleSaveNew();
    else handleSaveExisting();
  });

  const handleSaveNew = () => {
    sendJson({
      path: `/exhibitions/${exhibition.exhibitionId}/competitions`,
      method: "POST",
      body: collectInputs(),
      onSuccess: ({ competitionId }: { competitionId: string }) => {
        refreshGlobalExhibition();
        history.replace(
          competitionEditPagePath(exhibition.exhibitionId, competitionId)
        );
      },
    });
  };
  const handleSaveExisting = () => {
    sendJson({
      path: `/exhibitions/${exhibition.exhibitionId}/competitions/${competition.competitionId}`,
      method: "PUT",
      body: collectInputs(),
      onSuccess: refreshGlobalExhibition,
    });
  };

  const collectInputs = () => ({
    animalStandardId: animalStandard.animalStandardId,
    competitionName,
    competitionUnits: units
      ?.filter((u) => u.isSelected)
      .map((u) => ({
        unitId: u.unit.unitId,
        isGenderMandatory: u.isGenderMandatory,
        strikeResult: u.strikeResult,
      })),
    pointCalculation,
    ratingAggregation,
    rankingLevel,
    rankingMode,
    hasSwissChampion,
    champions:
      championLevels
        ?.filter((c) => c.isSelected)
        ?.reduce((result, champion) => {
          result[champion.level] = {
            level: champion.level,
            championCode: champion.championCode,
            animalMin: parseInt(champion.animalMin),
          };
          return result;
        }, {} as any) ?? [],
    medalDistribution: medalDistribution,
    medals: medals
      ?.filter((m) => m.isSelected)
      .reduce((result, medal) => {
        result[medal.medal] = {
          limit: parseInt(medal.limit),
          medalCode: medal.medalCode,
        };
        return result;
      }, {} as any),
    hasSingleMedal: hasSingleMedal,
    organizationIds: organizations.map((o) => o.organizationId),
  });

  return (
    <>
      <BottomBarPage
        title={exhibition.exhibitionName}
        subtitle={isNewCompetition ? "Neue Wertung" : "Wertung bearbeiten"}
        bottomBarContent={
          isNewCompetition ? (
            <Button
              color="secondary"
              variant="contained"
              disabled={!valid || saving}
              onClick={handleSaveNew}
            >
              Speichern
            </Button>
          ) : (
            <Button
              color="secondary"
              variant="contained"
              disabled={!valid || saving}
              onClick={handleSaveExisting}
            >
              Änderungen speichern
            </Button>
          )
        }
      >
        {animalStandard && championLevels ? (
          <Grid container spacing={spacing3}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                id="competitionName"
                name="competitionName"
                label="Name der Wertung"
                value={competitionName}
                onChange={handleCompetitionNameChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormSectionTitle title="Standard" />
              <Grid item xs={12}>
                <Typography variant="body1">
                  {animalStandard.animalStandardName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormSectionTitle title="Einheiten *" />
              <Grid item xs={12}>
                {units?.length ? (
                  <UnitTable
                    units={units.sort((a, b) => a.unit.sort - b.unit.sort)}
                    onChange={setUnits}
                  />
                ) : (
                  <Typography color="error">
                    {`Für den Standard ${animalStandard.animalStandardName} sind keine Einheiten definiert, deshalb kann keine Wertung für den Standard erstellt werden.`}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={spacing3}>
              <FormSectionTitle title="Punkte" />
              {applicablePointCalculations(animalStandard).length > 1 ? (
                <Grid item xs={12} sm={6}>
                  <PointCalculationRadios
                    value={pointCalculation as PointCalculation}
                    onChange={setPointCalculation}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={6}>
                <Radios
                  label="Berechnung Gesamtpunktzahl"
                  fieldName="ratingAggregation"
                  items={ratingAggregations}
                  value={ratingAggregation}
                  onChange={(v) => setRatingAggregation(v as RatingAggregation)}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={spacing3}>
              <FormSectionTitle title="Ränge" />
              <Grid item xs={12} sm={6}>
                <Radios
                  label="Rangierung innerhalb"
                  fieldName="rankingLevel"
                  items={applicableRankingLevels}
                  value={rankingLevel}
                  onChange={(v) => setRankingLevel(v as RankingLevelKey)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Radios
                  label="Rangvergabe bei Punktegleichheit"
                  fieldName="rankingMode"
                  items={rankingModes}
                  value={rankingMode}
                  onChange={(v) => setRankingMode(v as RankingMode)}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={spacing3}>
              <FormSectionTitle title="Stufensieger" />
              {championLevels
                .sort((a, b) =>
                  a.level === b.level ? 0 : a.level < b.level ? -1 : 1
                )
                .map((c) => (
                  <Grid key={c.level} item xs={12} sm={6}>
                    <ChampionLevelItem
                      key={c.levelName}
                      config={c}
                      onChange={(c) => {
                        setChampionLevels((old) => [
                          ...(old ?? []).filter((o) => o.level !== c.level),
                          c,
                        ]);
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid item xs={12} container spacing={spacing3}>
              <FormSectionTitle title="Medaillen" />
              <Grid item xs={12}>
                <Radios
                  className={classes.radioInline}
                  label="Verteilung"
                  fieldName="medaillen"
                  items={medalDistributions}
                  value={medalDistribution}
                  onChange={(v) => setMedalDistribution(v as MedalDistribution)}
                />
              </Grid>
              {medals
                .sort((a, b) => a.sort - b.sort)
                .map((c) => (
                  <Grid key={c.medal} item xs={12} sm={4}>
                    <MedalItem
                      key={c.medal}
                      config={{
                        ...c,
                        valueLabel: medalDistributions.filter(
                          (d) => d.value === medalDistribution
                        )[0].valueLabel,
                      }}
                      onChange={(c) => {
                        setMedals((old) => [
                          ...old.filter((o) => o.medalName !== c.medalName),
                          c,
                        ]);
                      }}
                    />
                  </Grid>
                ))}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasSingleMedal}
                      onChange={(e) => setSingleMedal(e.target.checked)}
                      name="HasSingleMedal"
                    />
                  }
                  label="Nur eine Medaille pro Teilnehmer innerhalb der Rangierung"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormTitle>Schweizermeister</FormTitle>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasSwissChampion}
                    onChange={(e) => setHasSwissChampion(e.target.checked)}
                    name="SwissChampion"
                  />
                }
                label="Schweizermeister ermitteln"
              />
            </Grid>
            <Grid item xs={12}>
              <FormTitle>Vereine</FormTitle>
              <OrganizationList
                exhibitionId={exhibition.exhibitionId}
                value={organizations}
                onChange={setOrganizations}
              />
            </Grid>
          </Grid>
        ) : null}
      </BottomBarPage>
    </>
  );
}

export default CompetitionEditPage;

interface AnimalStandard {
  animalStandardId: string;
  animalStandardName?: string;
  units: Unit[];
  hasReferenceRating: boolean;
  hasAgeClass: boolean;
  hasBreedSizeLevel: boolean;
  hasColorLevel: boolean;
  hasSubGroupLevel: boolean;
}

interface Unit {
  unitId: string;
  captions: { language: string; unitName: string }[];
  isStrikeResultPossible: boolean;
  sort: number;
}
