import logger from "../crossCutting/logger";
import { makeUrl } from "../crossCutting/urls";

/** Creates a general purpose FetchClient i.e. one that is not specific to Klexpo. */
export function createFetchClient(
  baseUrl: string,
  language: string,
  requestInterceptor?: RequestInterceptor,
  responseInterceptor?: ResponseInterceptor
): FetchClient {
  return function (path: string, options: FetchOptions) {
    const url = makeUrl`${baseUrl}/${path}`;
    const defaultRequest = new Request(url, options);
    const request = requestInterceptor
      ? requestInterceptor(defaultRequest)
      : defaultRequest;
    return fetch(request)
      .then((res) => {
        if (responseInterceptor && responseInterceptor(res)) {
          return;
        }
        if (options.raw) {
          return res;
        } else if (res.ok) {
          logger.debug("response ok");
          return asTextOrJson(res);
        } else {
          logger.error("response NOT ok, status:", res.status, res.statusText);
          return asTextOrJson(res).then((json) => Promise.reject(json));
        }
      })
      .catch((error) =>
        Promise.reject(analyzeError(error, defaultErrorMessages[language]))
      );
  };
}

function asTextOrJson(res: Response) {
  if (
    (res.headers.get("Content-Type")?.toLowerCase().indexOf("/json") ?? -1) ===
    -1
  ) {
    return res.text();
  } else {
    return res.json();
  }
}

export interface FetchClient {
  (path: string, options: FetchOptions): Promise<any>;
}

function analyzeError(error: any, fallbackMessage: string): FetchError {
  if (typeof error?.friendlyMessage === "string") {
    return { friendlyMessage: error.friendlyMessage, errorFromServer: error };
  } else if (typeof error === "object") {
    return { friendlyMessage: fallbackMessage, errorFromServer: error };
  } else {
    return { friendlyMessage: fallbackMessage };
  }
}

export interface FetchOptions {
  method: FetchMethod;
  headers?: { [key: string]: string };
  body?: any;
  raw?: boolean;
}

export type FetchMethod = "GET" | "POST" | "PUT" | "DELETE";

export interface FetchError {
  /**
   * Message that is understandable to the user (content and language wise).
   */
  friendlyMessage: string;
  /**
   * Error (JSON) object as received from server.
   */
  errorFromServer?: any;
}

const defaultErrorMessages: { [language: string]: string } = {
  de: "Beim Abrufen der Daten vom Server ist ein Fehler aufgetreten.",
  fr: "Une erreur s'est produite lors de la récupération des données sur le serveur.",
};

interface ResponseInterceptor {
  /**
   * @returns A value indicating if the response has been handled.
   */
  (res: Response): boolean;
}

interface RequestInterceptor {
  (req: Request): Request;
}
