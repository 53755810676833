import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import {
  NavLink as RouterLink,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import DateField from "../../../crossCutting/dataInput/DateField";
import { dateString } from "../../../crossCutting/date";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import Page from "../../../crossCutting/pages/Page";
import { useSendJson } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";
import { Exhibition } from "../apiTypes";
import { useRefreshExhibition } from "../ExhibitionContext";
import OrganizersTabPanel from "./OrganizersTabPanel";
import PublicationTabPanel from "./PublicationTabPanel";

export type ExhibitionSettingsExhibition = Pick<
  Exhibition,
  | "exhibitionId"
  | "exhibitionName"
  | "city"
  | "orderInfo"
  | "registrationInfo"
  | "isOrganizationRegistrationEmailActive"
  | "organizationRegistrationEmail"
> & { deadline: Date; startDate: Date; endDate: Date };
export interface ExhibitionSettingsPageProps {
  exhibition: ExhibitionSettingsExhibition;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(spacing3),
    marginTop: theme.spacing(spacing3),
  },
}));

const ExhibitionSettingsPage: React.FunctionComponent<
  ExhibitionSettingsPageProps
> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [exhibition, setExhibition] = React.useState(props.exhibition);
  const refreshExhibition = useRefreshExhibition();
  const { sendJson: save, loading } = useSendJson({
    success: "Die Einstellungen wurden gespeichert",
    error: "Fehler beim Speichern der Einstellungen.",
  });
  const handleSave = () => {
    save({
      path: `exhibitions/${props.exhibition.exhibitionId}`,
      method: "PUT",
      body: {
        exhibitionName: exhibition.exhibitionName?.trim(),
        city: exhibition.city?.trim(),
        orderInfo: exhibition.orderInfo?.trim() || null,
        registrationInfo: exhibition.registrationInfo?.trim() || null,
        startDate: dateString(exhibition.startDate),
        endDate: dateString(exhibition.endDate),
        deadline: dateString(exhibition.deadline),
        isOrganizationRegistrationEmailActive:
          exhibition.isOrganizationRegistrationEmailActive,
        organizationRegistrationEmail:
          exhibition.organizationRegistrationEmail?.trim() || null,
      },
      onSuccess: () => {
        refreshExhibition();
      },
    });
  };
  const valid =
    !!exhibition.exhibitionName?.trim() &&
    !!exhibition.city?.trim() &&
    !!exhibition.deadline &&
    (!exhibition.isOrganizationRegistrationEmailActive ||
      !!exhibition.organizationRegistrationEmail);
  const theme = useTheme();
  const largeOrBigger = useMediaQuery(theme.breakpoints.up("lg"));
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  return (
    <Page title={props.exhibition.exhibitionName} subtitle={t("configuration")}>
      <Tabs
        value={pathname}
        centered={largeOrBigger}
        variant={largeOrBigger ? "standard" : "scrollable"}
      >
        {allTabs.map((tab) => (
          <Tab
            key={tab.key}
            label={t(tab.labelKey)}
            value={`${url}/${tab.key}`}
            component={RouterLink}
            to={`${url}/${tab.key}`}
          />
        ))}
      </Tabs>
      <Container maxWidth="sm">
        <Paper className={classes.paper}>
          <Switch>
            <Route path={`${path}/general`}>
              <Grid container spacing={spacing3}>
                <Grid item xs={12}>
                  <TextField
                    label={t("nameOfTheExhibition")}
                    required
                    fullWidth
                    autoFocus
                    value={exhibition.exhibitionName}
                    onChange={(e) =>
                      setExhibition({
                        ...exhibition,
                        exhibitionName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t("city")}
                    required
                    fullWidth
                    value={exhibition.city}
                    onChange={(e) =>
                      setExhibition({ ...exhibition, city: e.target.value })
                    }
                  />
                </Grid>
                <Grid item container spacing={spacing3}>
                  <Grid item xs={6}>
                    <DateField
                      label="Von"
                      value={dateString(exhibition.startDate)}
                      onChange={(d) => {
                        const asDate = new Date(d);
                        setExhibition({
                          ...exhibition,
                          startDate: asDate,
                          endDate:
                            exhibition.endDate < asDate
                              ? asDate
                              : exhibition.endDate,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateField
                      label="Bis"
                      value={dateString(exhibition.endDate)}
                      onChange={(d) => {
                        setExhibition({ ...exhibition, endDate: new Date(d) });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DateField
                    label={t("deadLine")}
                    value={dateString(exhibition.deadline)}
                    onChange={(d) =>
                      setExhibition({ ...exhibition, deadline: new Date(d) })
                    }
                  />
                </Grid>{" "}
                <Grid container item xs={12} justify="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSave}
                    disabled={!valid || loading}
                  >
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </Route>
            <Route path={`${path}/texts`}>
              <Grid container spacing={spacing3}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    label={t("registrationInfoText")}
                    placeholder='Beispiel: "Die Gebühr pro Anmeldung beträgt Fr. 15. Dafür erhältst du Spass, einen Katalog und eine Plakette als Erinnerungspreis."'
                    fullWidth
                    multiline
                    rows={5}
                    value={exhibition.registrationInfo || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setExhibition({
                        ...exhibition,
                        registrationInfo: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t("orderInfoText")}
                    placeholder='Beispiel: "Es können Kataloge aber keine Erinnerungsmedaillen bestellt werden."'
                    fullWidth
                    multiline
                    rows={5}
                    value={exhibition.orderInfo || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setExhibition({
                        ...exhibition,
                        orderInfo: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid container item xs={12} justify="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSave}
                    disabled={!valid || loading}
                  >
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </Route>
            <Route path={`${path}/notifications`}>
              <Grid container spacing={spacing3}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          exhibition.isOrganizationRegistrationEmailActive
                        }
                        tabIndex={-1}
                        disableRipple
                        onChange={(_, checked) =>
                          setExhibition({
                            ...exhibition,
                            isOrganizationRegistrationEmailActive: checked,
                          })
                        }
                      />
                    }
                    label={t("sendRegistrationEmailToOrganization")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    label={t("email")}
                    fullWidth
                    value={exhibition.organizationRegistrationEmail || ""}
                    type="email"
                    onChange={(e) =>
                      setExhibition({
                        ...exhibition,
                        organizationRegistrationEmail: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid container item xs={12} justify="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSave}
                    disabled={!valid || loading}
                  >
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </Route>
            <Route path={`${path}/publication`}>
              <PublicationTabPanel exhibitionId={exhibition.exhibitionId} />
            </Route>
            <Route path={`${path}/organizers`}>
              <OrganizersTabPanel exhibitionId={exhibition.exhibitionId} />
            </Route>
            <Redirect from="/" to={`${url}/general`} />
          </Switch>
        </Paper>
      </Container>
    </Page>
  );
};

export default ExhibitionSettingsPage;

const allTabs = [
  {
    labelKey: "general" as const,
    key: "general" as const,
  },
  {
    labelKey: "infoTexts" as const,
    key: "texts" as const,
  },
  {
    labelKey: "publication" as const,
    key: "publication" as const,
  },
  {
    labelKey: "notifications" as const,
    key: "notifications" as const,
  },
  {
    labelKey: "organizers" as const,
    key: "organizers" as const,
  },
];
